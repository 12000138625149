<template>
  <div>
    <div class="perview-box" id="perviewImg">
      <img class="back-img" id="bgImg" :src="bgImg" alt="选择图片" />
      <div class="b-l-wm-box">
        <div class="first-line">
          <div class="now-time">{{ nowTime }}</div>
          <div class="split-line-box">
            <div class="split-line"></div>
          </div>
          <div class="date-weather">
            <div class="date">{{ nowDate }}</div>
            <div class="weather">
              {{ `${nowWeekly} ${weatherTxt} ${tmpTxt}℃` }}
            </div>
          </div>
        </div>
        <div class="second-line">{{ `${city}·${address}` }}</div>
      </div>
      <div class="b-r-wm-box">
        <img
          src="@/assets/img/watermark_real_time_anti_code.png"
          alt=""
          class="content-img"
        />
        <div class="br-anti-code">
          <div class="anti-code-txt">
            <img
              src="@/assets/img/bg_tv_anti_fake.png"
              alt=""
              class="txt_bg_shadow"
            />
          </div>
          <div class="anti-code-container">
            <img
              src="@/assets/img/bg_code_shadow.png"
              alt=""
              class="code-shadow"
            />
            <div class="anti-code">
              {{ getRandomKey() }}
            </div>
            </div>
        </div>
      </div>
    </div>
    <van-row class="btn-container">
      <van-col span="12">
        <van-button type="primary" @click="uploadImg">选择图片</van-button>
      </van-col>
      <van-col span="12">
        <van-button type="success" @click="converImg">生成图片</van-button>
      </van-col>
    </van-row>
    <van-cell-group inset>
      <van-field
        type="text"
        label="时间"
        placeholder="请选择"
        v-model="nowTime"
        @click="showSelectDate"
        readonly
      />
      <van-field
        type="text"
        label="天气"
        placeholder="请输入"
        v-model="weatherTxt"
      />
      <van-field
        type="text"
        label="温度"
        placeholder="只输入数字"
        v-model="tmpTxt"
      />
      <van-field type="text" label="城市" placeholder="请输入" v-model="city" />
      <van-field
        type="text"
        label="地址"
        placeholder="请输入"
        v-model="address"
      />
    </van-cell-group>
    <van-popup v-model:show="isShowSelectDate" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="选择日期时间"
        @cancel="hidenSelectDate"
        @confirm="confirmDatetime"
      />
    </van-popup>
    <input
      accept="image/*"
      class="sr-only"
      id="inputImagevue"
      name="file"
      type="file"
      style="visibility: hidden"
    />
    <van-overlay :show="showLoading">
      <div class="loading-warp">
        <van-loading size="24px" vertical>生成中...</van-loading>
      </div>
    </van-overlay>
    <!-- <img :src="resultImg" style="width: 100%;" /> -->
  </div>
</template>

<script>
// 默认水印 id 34
import { ref } from "vue";
import moment from "moment";
import html2canvas from "html2canvas";

const CACHE_INFO_KEY = "watermask_cache_info";

export default {
  name: "AppendWaterMask",
  data() {
    const currentDate = ref(new Date());
    const nowTime = moment(currentDate.value).format("HH:mm");
    const nowDate = moment(currentDate.value).format("YYYY-MM-DD");
    const nowWeekly = moment(currentDate.value).format("dddd");

    // 尝试获取缓存信息
    const lsInfo = localStorage.getItem(CACHE_INFO_KEY);
    let weatherTxt = "晴";
    let tmpTxt = "8";
    let city = "涡阳县";
    let address = "城关镇";
    if (lsInfo) {
      const cacheInfo = JSON.parse(lsInfo);
      weatherTxt = cacheInfo.weatherTxt;
      tmpTxt = cacheInfo.tmpTxt;
      city = cacheInfo.city;
      address = cacheInfo.address;
    }

    return {
      nowTime,
      nowDate,
      nowWeekly,
      isShowSelectDate: false,
      currentDate,
      weatherTxt,
      tmpTxt,
      city,
      address,
      bgImg: null,
      showLoading: false,
      resultImg: null,
    };
  },
  mounted() {
    const $inputImage = document.getElementById("inputImagevue");
    const _this = this;
    $inputImage.addEventListener("change", function () {
      const files = this.files;
      _this.onImgChange(files);
    });
  },
  methods: {
    getRandomKey() {
      const keys = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "0",
      ];

      let randomkey = "";

      for (let index = 0; index < 14; index++) {
        randomkey += keys[Math.floor(Math.random() * keys.length)];
      }

      return randomkey;
    },
    onImgChange(files) {
      this.bgImg = null;
      if (files && files.length) {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
          this.bgImg = e.target.result;
        };
      }
    },
    converImg() {
      this.showLoading = true;
      const bgImg = document.getElementById("bgImg");

      const perviewImg = document.getElementById("perviewImg");
      html2canvas(perviewImg, {
        imageTimeout: 25000,
        useCORS: true, // 【重要】开启跨域配置
        allowTaint: true, // 允许跨域图片
        logging: true,
        scale: bgImg.naturalWidth / 360.0,
        backgroundColor: null,
      })
        .then((canvas) => {
          const dataURL = canvas.toDataURL("image/jpeg", 1.0);
          this.resultImg = dataURL;
          console.log("生成成功");
          this.showLoading = false;

          const a = document.createElement("a");
          a.href = dataURL;
          a.setAttribute('download', `${Date.now().toString() }.jpg`)
          a.click();

          this.saveCacheInfo();
        })
        .catch(() => {
          this.showLoading = false;
        });
    },
    saveCacheInfo() {
      const cacheInfo = {
        weatherTxt: this.weatherTxt,
        tmpTxt: this.tmpTxt,
        city: this.city,
        address: this.address,
      };

      const json = JSON.stringify(cacheInfo);
      localStorage.setItem(CACHE_INFO_KEY, json);
    },
    showSelectDate() {
      console.log("click");
      this.isShowSelectDate = true;
    },
    hidenSelectDate() {
      this.isShowSelectDate = false;
    },
    confirmDatetime() {
      this.isShowSelectDate = false;
      this.nowTime = moment(this.currentDate).format("HH:mm");
      console.log("time", this.nowTime);
      this.nowDate = moment(this.currentDate).format("YYYY-MM-DD");
      this.nowWeekly = moment(this.currentDate).format("dddd");
    },
    uploadImg() {
      //实现模拟点击
      const e = document.createEvent("MouseEvents");
      e.initEvent("click", true, true);
      document.getElementById("inputImagevue").dispatchEvent(e);
    },
  },
};
</script>

<style>
@font-face {
  font-family: "Din";
  src: url("../assets/font/DIN_Alternate_Bold.ttf");
}

@font-face {
  font-family: "HY-qihei-65";
  src: url("../assets/font/HYQiHeiX2-FEW.ttf");
}

@font-face {
  font-family: "HY-qihei-85";
  src: url("../assets/font/HYQiHeiX2-85W.otf");
}

@font-face {
  font-family: "PT-bold";
  src: url("../assets/font/PTMono-Bold.ttf");
}

.loading-warp {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background: #fff;
  overflow: hidden;
  margin-top: -50px;
  margin-left: -50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.perview-box {
  width: 360px;
  height: 480px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

.back-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.b-l-wm-box {
  position: absolute;
  bottom: 5px;
  left: 8px;
  height: 71px;
  width: 260px;
  color: #fff;
  text-align: left;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.first-line {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 53px;
}

.now-time {
  line-height: 53px;
  height: 53px;
  font-size: 38px;
  font-family: "Din";
}

.split-line-box {
  height: 34px;
  padding-top: 5px;
  padding-bottom: 3px;
  width: 3px;
  margin-left: 8px;
}
.split-line {
  height: 34px;
  width: 3px;
  background-color: #ffc233;
}

.date-weather {
  margin-left: 8px;
  height: 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.date {
  height: 21px;
  line-height: 21px;
  font-size: 16px;
  font-family: "HY-qihei-65";
}

.weather {
  height: 21px;
  line-height: 21px;
  font-size: 16px;
  font-family: "HY-qihei-65";
}

.second-line {
  font-family: "HY-qihei-65";
  font-size: 14px;
  text-align: left;
  height: 18px;
  line-height: 18px;
  overflow: hidden;
}

.b-r-wm-box {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 143px;
}

.br-anti-code {  
  text-align: right;
  position: absolute;
  right: 0px;
  bottom: 1.5px;
  color: #fff;
  height: 8px;
  width: 65px;
  display: flex;
  justify-content: right;
  align-items: center;
  flex-direction: row;
}

.anti-code-txt {
  width: 11px;
  height: 8px;
  position: relative;
}

.txt_bg_shadow {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.code-shadow {
  position: absolute;
  top: 0.5px;
  left: 0px;
  width: 53px;
  height: 8px;
  z-index: 1;
  object-fit: cover;
  object-position: center;
  vertical-align: bottom;
}

.anti-code-container {
  margin-left: 1px;
  width: 53px;
  height: 8px;
  position: relative;
}

.anti-code {
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  font-family: "PT-bold";
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  width: 53px;
  transform: scale(0.5) translate(-50%, -50%);
}

.content-img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  vertical-align: bottom;
}

.btn-container {
  margin: 10px 20px 0px;
}
</style>