import { createApp } from "vue";
import App from "./App.vue";
import { Button, Row, Col, Field, CellGroup, Cell, DatetimePicker, Popup, Uploader, Overlay, Loading   } from "vant";
import moment from 'moment';

moment.locale('zh', {
  weekdays: '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
});

moment.locale('zh');

createApp(App)
.use(Button)
.use(Row)
.use(Col)
.use(CellGroup)
.use(Field)
.use(Cell)
.use(DatetimePicker)
.use(Popup)
.use(Uploader)
.use(Overlay)
.use(Loading)
.mount("#app");
